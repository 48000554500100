import Lang from "@/resources/js/components/Lang";
import AppShow from "@/resources/js/Abstract/AppShow";


export default {
    name: "ArtistPage",
    mixins: [AppShow, Lang],

    data: function () {
        return {
            data: {
                name: null,
                about: null,
                discography: {
                    value: null,
                    type: String
                },
                photos: null,
                main_photo: null,
                alias: null,
            }
        }
    },

    mounted() {

    },
    methods: {

    },
    watch: {
        currentLang: function() {
            this.loadData();
        }
    }
}